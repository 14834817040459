<template>
	<div class="TH_page-investList">
		<div class="container" v-if="spinner">
			<div class="row">
				<div class="col">
					<div class="d-flex justify-content-center" style="padding: 30px 0;">
						<div class="spinner-border text-light" role="status">
							<span class="sr-only">Loading...</span>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="container" v-if="!spinner && fundList.length > 0">
			<div class="row">
				<div class="col-12">
					<div class="FM_balanceBox">
						<p class="text-title">
							TOTAL BALANCE | <b>USDT</b>
						</p>
						<p class="text-balance">
							<span class="int-number">{{ amount.big }}</span>
							<span class="float-number">{{ amount.small }}</span>
						</p>
					</div>
				</div>
				<div class="col-6 col-lg-3" v-for="item in fundList" :key="item.id">
					<router-link :to="'/invest/detail/' + item.id" class="FM_fundBox">
						<span class="iconBox" :class="{'fund-fomo': item.code === 'fomo_fund', 'fund-safe': item.code === 'safe_fund', 'fund-btc': item.code === 'btc_fund', 'fund-eth': item.code === 'eth_fund', }"></span>
						<h2>{{ item.name.replace('Fund', '').toUpperCase() }}</h2>
						<h3 v-if="item.code === 'fomo_fund'" class="empty">&nbsp;</h3>
						<h3 v-else>{{ (item.apy/100) > 0 ? 'APY' : ''  }} {{ (item.apy/100).toFixed(2) }}%</h3>
						<p class="text-content" v-if="item.code === 'fomo_fund'">The Holy Grail of growth strategies</p>
						<p class="text-content" v-if="item.code === 'safe_fund'">Indestructible protection from volatility</p>
						<p class="text-content" v-if="item.code === 'btc_fund'">Long live the crowned king of crypto</p>
						<p class="text-content" v-if="item.code === 'eth_fund'">Raise your weapon for the Ethereal light</p>
						<span class="link-detail">More Info</span>
					</router-link>
				</div>
				<div class="col-lg-6 offset-lg-3">
					<button type="button" @click="logoutBtn" class="btn btn-outline-success btn-block font-weight-bold FM_btn FM_logout-list" :disabled="logoutSpinner">
						<span class="spinner-border spinner-border-sm" role="status" aria-hidden="true" v-show="logoutSpinner"></span>
						Logout
					</button>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { Ajax } from "@/api/index.js"
import { API } from "@/api/api.js"
import { paddingRight, deepCopy, formatNumber } from "@/utils/index.js"
import { mapState, mapActions, mapMutations } from 'vuex'

export default {
	name: "List",
	data() {
		return{
			spinner: true,
			logoutSpinner: false,
			amount: {
				big: '0.00',
				small: '0000'
			},
			fundList: []
		}
	},
	async created() {
		await this.getBalance();
		await this.getFundList();
	},
	methods: {
		...mapActions(['logout']),
		formatNumber,
		logoutBtn() {
			this.logoutSpinner = true;
			this.$Progress.start();

			this.$store.dispatch('logout').then((result) => {
				this.logoutSpinner = false;
				this.$Progress.finish();
			}).catch((result) => {
				console.log('catch', result)
			})
		},
		getFundList() {
			Ajax(
				API.fundList.method,
				API.fundList.url
			).then(result => {
				if (result.success !== 1) {
					return;
				}
				this.fundList = result.data;
				this.spinner = false;
			});
		},
		getBalance() {
			Ajax(
				API.getBalanceInfo.method,
				API.getBalanceInfo.url
			).then(result => {
				if (result.success !== 1) {
					return;
				}
				let amount = this.formatNumber((result.data.value_usd / 1000000).toFixed(6));
				let int = amount.toString().split('.')[0];
				let float = amount.toString().split('.')[1];
				this.amount.big = (int + '.' + float.slice(0, 2)).toString();
				this.amount.small = (float.slice(2)).toString();
			});
		}
	}
}
</script>

<style scoped>

</style>